<template>
	<a-row>

		<a-col :span="6">
			<div class="form-avatar">
				<a-upload name="file" :before-upload="onBeforeUpload">
					<img class="avatar" :src="formdata.avatar" />
					<div class="action">
						<span class="icon icon-feedback"></span>
              {{ $t('changeAvatar') }}
					</div>
				</a-upload>
			</div>
			<div class="form-nickname">{{ minfo.nickname }}</div>
		</a-col>
		<a-col :span="16">
			<a-form-model :model="formdata" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
				<a-form-model-item :label="this.$t('nickname')" prop="nickname"><a-input v-model="formdata.nickname" :placeholder="this.$t('nicknameText')" /></a-form-model-item>
				<a-form-model-item :label="this.$t('phoneNumber')"><a-input v-model="minfo.phone" disabled /></a-form-model-item>
				<a-form-model-item  :label="this.$t('personalIntro')"><a-input v-model="formdata.sign" type="textarea" :rows="6" :placeholder="this.$t('maxLength')" /></a-form-model-item>
				<a-form-model-item :wrapper-col="{ span: 20, offset: 4 }"><button class="button button-block" @click="onSubmit" type="button">
            {{     $t('saveSubmission') }}</button></a-form-model-item>
			</a-form-model>
		</a-col>
	</a-row>
</template>

<script>
import { mapState, mapAction } from 'vuex';
import OSS from 'ali-oss';
export default {
	name: 'UserInfo',
	data() {
		return {
			formdata: {
				nickname: '',
				sign: '',
				avatar: ''
			}
		};
	},
	computed: mapState(['minfo']),
	beforeRouteEnter(to, from, next) {
		next(vm => vm.getInfo());
	},
	methods: {
		async getInfo() {
			const { nickname, avatar, sign } = await this.$store.dispatch('syncMemberInfo');
			this.formdata = { nickname, avatar, sign };
		},
		async onBeforeUpload(file) {
			if (this.type == 'image' && !/image\//.test(file.type)) {
				this.$message.error(this.$t('uploadImage'));
				return false;
			}
			if (this.type == 'image' && file.size > 1024 * 1024 * 2) {
				this.$message.error(this.$t('uploadImgLimit'));
				return false;
			}
			const loading = this.$message.loading(this.$t('uploading'), 0);
			const response = await this.$api.get('/ali/token');
			if (response && response.code == 200) {
				const { url, accessKeyId, accessKeySecret, securityToken, bucketName, endpoint } = response.data;
				const client = new OSS({
					endpoint,
					accessKeyId,
					accessKeySecret,
					stsToken: securityToken,
					bucket: bucketName,
					secure: /^https/.test(location.href)
				});
				const result = await client.put('web/avatar/' + Date.now() + '.png', file).catch(e => {
					loading();
				});

                if (result && result.name) {
					this.formdata.avatar = url + '/' + result.name;
					this.$message.success(this.$t('uploaded'));
				}
			}
			loading();
			return Promise.reject(false);
		},
		async onSubmit() {
			const { nickname, avatar, sign } = this.formdata;
			const data = {};
			if (nickname && this.minfo.nickname != nickname) {
				data.nickname = nickname;
			}
			if (!nickname.replace(/\s/g, '')) {
				this.$message.error(this.$t('nicknameText'));
				return;
			}
			if (nickname.length > 12) {
				this.$message.error(this.$t('maxLengthNickname'));
				return;
			}

			if (avatar && this.minfo.avatar != avatar) {
				data.avatar = avatar;
			}
			if (this.minfo.sign != sign) {
				data.sign = sign;
			}
			if (Object.keys(data).length === 0) {
				this.$message.success(this.$t('changed'));
				return;
			}
			const response = await this.$api.post('/web/update_user', data);
			if (response) {
				this.$store.dispatch('setMemberInfo', Object.assign(this.minfo, data));
				this.$message.success(this.$t('changed'));
			}
		}
	}
};
</script>
